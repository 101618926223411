<template>
    <section class="cards-container">
            <h4>Relatório de repasse</h4>
            <div class="cards">
                <div class="card-container">
                    <SimpleCard 
                        :isMoney="true"
                        cursor="pointer"
                        title="Valor fat. clínica"
                        :value="cards['Valor fat. clínica']"
                        color="var(--dark-blue)"
                    />
                </div>
                <div class="card-container">
                    <SimpleCard 
                        :isMoney="true"
                        cursor="pointer"
                        title="Valor repassado"
                        :value="cards['Valor repassado']"
                        color="var(--neutral-700)"
                    />
                </div>
                <div class="card-container">
                    <SimpleCard 
                        :isMoney="true"
                        cursor="pointer"
                        title="Valor restante"
                        :value="cards['Valor restante']"
                        color="#1388AD"
                    />
                </div>
                <div class="card-container">
                    <SimpleCard 
                        :isMoney="false"
                        cursor="pointer"
                        title="% sobre valor fatur."
                        :value="cards['% sobre valor fatur.']"
                        color="var(--blue-500)"
                    />
                </div>
                <div class="card-container">
                    <SimpleCard 
                        :isMoney="false"
                        cursor="pointer"
                        title="Qtde de proced."
                        :value="cards['Qtde de proced.']"
                        color="var(--states-success)"
                    />
                </div>
            </div>
    </section>
</template>

<script>
export default {
    components: {
        SimpleCard: () => import('@/components/Dashboard/SimpleCard.vue')
    },

    props: {
        cards: [Array, Object],
    },  
}
</script>

<style lang="scss" scoped>
    .cards-container {
        h4 {
            color: var(--dark-blue); 
            font-weight: 600;
            font-size: 18px;
        }

        .cards {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 1rem;

            .card-container {
                height: 100px;
                width: 100%;

                .card {
                    width: 100%;
                    overflow: hidden;
                    @media(max-width: 1368px){
                        padding: 16px 20px;
                    }
                }
            }
        }
    }
</style>